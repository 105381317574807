export function identifyUserMixpanel({ email, firstName, userId, lastName }) {
  if (window.mixpanel == null) {
    return;
  }
  mixpanel.identify(userId);
  mixpanel.people.set({
    $first_name: firstName,
    $last_name: lastName,
    $email: email,
  });
}

export function trackEvent(name: string, data?: { [index: string]: unknown }) {
  if (window.mixpanel == null) {
    return;
  }
  mixpanel.track(name, data);
}

export function trackFNOLEvent(event) {
  if (window.mixpanel == null || !event) {
    return;
  }

  const { name, ...rest } = event;
  mixpanel.track(name, rest);
}

export function setMixpanelSuperProperties(properties) {
  if (window.mixpanel == null) {
    return;
  }
  mixpanel.register(properties);
}

export function resetAnalytics() {
  if (window.mixpanel == null) {
    return;
  }
  mixpanel.reset();
}

export function setMixpanelProfileProperties(properties) {
  if (window.mixpanel == null) {
    return;
  }
  mixpanel.people.set(properties);
  mixpanel.identify();
}
