import type { Primitive } from "@sentry/core";
import {
  browserProfilingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
  setContext,
  setTag,
  setUser,
  wrapCreateBrowserRouterV6,
} from "@sentry/react";
import { useEffect } from "react";
import {
  type RouteObject,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { CONFIG } from "config";

const SENTRY_ENABLED = CONFIG.APP_ENV === "production";

export function initSentry() {
  init({
    enabled: SENTRY_ENABLED,
    // proxied through ingress -> relay -> sentry
    dsn: `https://abf24f735328cdc768c9484379f7766d@${window.location.host}/p/telemetry/relay/4508718262845440`,
    release: `keystone-ui-client@${__VERSION__}`,
    environment: CONFIG.APP_ENV,
    integrations: [
      browserProfilingIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });
}

export function setSentryUser(user: { userId: string; email: string } | null) {
  setUser(user);
}

export function setSentryLinks(context: Record<string, string>) {
  setContext("links", context);
}

export function setSentryFeatFlags(flags: Record<string, unknown>) {
  setContext("feature flags", flags);
}

export function setSentryTag(key: string, value: Primitive) {
  setTag(key, value);
}

export function wrappedBrowserRouter(routes: RouteObject[], options?: unknown) {
  const sentryCreateBrowserRouter =
    wrapCreateBrowserRouterV6(createBrowserRouter);
  return sentryCreateBrowserRouter(routes, options);
}

/**
 * Sends entire error with stack trace to Sentry.
 * @param err
 */
export function captureException(err: unknown) {
  if (!SENTRY_ENABLED) {
    console.error(err);
  }
  sentryCaptureException(err);
}

/**
 * Sends error message string to Sentry, no stack trace is included.
 * @param msg
 */
export function captureErrorMessage(msg: string) {
  if (!SENTRY_ENABLED) {
    console.error(msg);
  }
  sentryCaptureMessage(msg, "error");
}
