import {
  ApolloClient,
  ApolloLink,
  type DefaultContext,
  HttpLink,
  InMemoryCache,
  type ServerError,
  from,
  split,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SpanKind, SpanStatusCode } from "@opentelemetry/api";

import { CONFIG } from "config";
import { GraphqlError } from "data/ss-error";
import {
  authHeaders,
  handleUnauthorized,
  logError,
  logException,
  traceProvider,
} from "utils";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const authMiddleware = setContext(async (req, prevCtx) => {
  const nextCtx: DefaultContext = { headers: req.context?.headers ?? {} };
  nextCtx.credentials = "include";
  nextCtx.headers = authHeaders(
    prevCtx.headers ?? {},
    prevCtx.clientName !== "keystone-api"
  );
  return nextCtx;
});

const keystoneProxyLink = new BatchHttpLink({
  uri: `${CONFIG.KEYSTONE_PROXY_HREF}/api/graphql`,
  headers: { "Cache-Control": "no-cache" },
});

const keystoneAPILink = new HttpLink({
  uri: `${CONFIG.KEYSTONE_API_HREF}/graphql`,
});

const splitLink = split(
  (operation) => {
    return operation.getContext().clientName === "keystone-api";
  },
  keystoneAPILink, // apollo use this link if context.clientName is "keystone-api"
  keystoneProxyLink // otherwise will send to this
);

const spanCreateLink = new ApolloLink((operation, forward) => {
  const tracer = traceProvider.getTracer("@apollo/client");
  const span = tracer.startSpan(`gql.${operation.operationName}`, {
    startTime: operation.getContext().start,
    attributes: {},
    kind: SpanKind.CLIENT,
  });

  const spanContext = span.spanContext();

  operation.setContext({
    span,
    headers: {
      ...operation.getContext().headers,
      "X-B3-TraceId": spanContext.traceId,
      "X-B3-SpanId": spanContext.spanId,
      "X-B3-Sampled": "1",
      traceparent: `00-${spanContext.traceId}-${spanContext.spanId}-01`,
    },
  });

  return forward(operation).map((data) => {
    span.end();
    return data;
  });
});

const spanErrorLink = onError(({ networkError, graphQLErrors, operation }) => {
  const span = operation.getContext().span;
  if (!span) {
    return;
  }

  span.setStatus({ code: SpanStatusCode.ERROR });

  if (networkError) {
    if (
      [UNAUTHORIZED, FORBIDDEN].includes(
        (networkError as ServerError).statusCode
      )
    ) {
      span.recordException({
        message: `[Unauthorized] ${networkError}`,
      });
    } else {
      span.recordException({
        message: `[Network Error] ${networkError}`,
      });
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      span.recordException({
        message: `Message: ${err.message}, Locations: ${err.locations}, Path: ${err.path}`,
      });
    });
  }
});

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  if (networkError) {
    if (
      [UNAUTHORIZED, FORBIDDEN].includes(
        (networkError as ServerError).statusCode
      )
    ) {
      handleUnauthorized({ endpoint: "graphql" });
    } else {
      logError(
        `Apollo Network Error: ${operation.operationName} (${networkError.name}: ${networkError.message})`
      );
    }
  }

  if (graphQLErrors) {
    logException(
      new GraphqlError(
        `${operation.operationName} [${graphQLErrors
          .map((err) => err.message)
          .join(", ")}]`
      )
    );
  }
});

export const apolloClient = new ApolloClient({
  link: from([
    spanCreateLink,
    spanErrorLink,
    errorLink,
    authMiddleware,
    splitLink,
  ]),
  cache: new InMemoryCache(),
  credentials: "include",
});
