import { SSError } from "data/ss-error";

import { captureException } from "./sentry";

/**
 * In production an error will be created using the provided message and sent
 * to Sentry. Use this method when an Error object isn't already available.
 * Otherwise, send the error with `logException(err: Error)`.
 */
export function logError(msg: string) {
  const err = new SSError(msg);
  captureException(err);
}

/**
 * In production an error event will be sent to Sentry.
 */
export function logException(err: Error) {
  captureException(err);
}
