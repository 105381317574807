import { FullStory, init } from "@fullstory/browser";

let FS_INITIALIZED = false;

export function initFullstory() {
  init({ orgId: "XMPRW" });
  FS_INITIALIZED = true;
}

type FsUser = {
  userId: string;
  email: string;
  displayName: string;
};

export function identifyFullstoryUser({ userId, email, displayName }: FsUser) {
  if (!FS_INITIALIZED) return;
  FullStory("setIdentity", {
    uid: userId,
    properties: {
      displayName,
      email,
      fullname_str: displayName,
      app: "mss",
    },
  });
}

export function getFullstorySession() {
  if (!FS_INITIALIZED) return;
  return FullStory("getSession");
}
